import { Routes, Route, Outlet, Navigate } from "react-router-dom";

// css
import "./App.scss";

// components
import React, { useState, useEffect } from "react";

// 404
import NotFound from "./pages/404";

//protected route
import ProtectedRoute from "./components/ProtectedRoute";

//login
import Login from "./pages/userManagement/login/Login";

// dashboard
import Layout from "./pages/dashboard/Layout";
import Dashboard from "./pages/dashboard/Dashboard";

//profile
import Profile from "./pages/userManagement/profile/Profile";

// employee database
import Employee from "./pages/employee/Employee";
import AddEmployee from "./pages/employee/AddEmployee";
import EditEmployee from "./pages/employee/EditEmployee";

// user management
//users
import Users from "./pages/userManagement/users/Users";
import AddUser from "./pages/userManagement/users/AddUser";
import EditUser from "./pages/userManagement/users/EditUser";
import ResetPassword from "./pages/userManagement/users/ResetPassword";
//roles
import RoleUser from "./pages/userManagement/roleUser/RoleUser";
//feature
import ManagementFeature from "./pages/userManagement/managementFeature/ManagementFeature";

//customer database
import CustomerList from "./pages/customerDatabase/CustomerList";
import AddCustomer from "./pages/customerDatabase/AddCustomer";
import EditCustomer from "./pages/customerDatabase/EditCustomer";

//customer review
import CustomerReviewList from "./pages/customerReview/CustomerReviewList";
import AddEditReviewReply from "./pages/customerReview/AddEditReviewReply";

//hotel management
//depertment
import Department from "./pages/department/Department";
//hotel
import Hotel from "./pages/hotel/Hotel";
import AddEditHotel from "./pages/hotel/AddEditHotel";
//product
import ProductList from "./pages/product/ProductList";
import AddEditProduct from "./pages/product/AddEditProduct";

// asset management
//partnership
import PartnershipList from "./pages/salesForecast/partnership/PartnershipList";
import AddEditPartnership from "./pages/salesForecast/partnership/AddEditPartnership";
//pic
import PersonInChargeList from "./pages/salesForecast/personInCharge/PersonInChargeList";
import AddEditPic from "./pages/salesForecast/personInCharge/AddEditPic";

//system configuration
//platform
import Platform from "./pages/platform/Platform";
import AddEditPlatform from "./pages/platform/AddEditPlatform";
//hotel account
import HotelAccount from "./pages/hotelAccount/HotelAccount";
import AddEditHotelAccount from "./pages/hotelAccount/AddEditHotelAccount";
//product type
import ProductType from "./pages/product/ProductType";
//customer segmentation
import CustomerSegmentation from "./pages/customerSegmentation/CustomerSegmentation";
//response message
import ResponseMessageList from "./pages/chatbot/response-message/ResponseMessageList";
// faq
import FaqList from "./pages/chatbot/faq/FaqList";
//backup time setup
import BackupTimeSetup from "./pages/backupDatabase/BackupTimeSetup";

//whatsapp broadcast
//broadcast template
import BroadcastMessageTemplate from "./pages/broadcastMessage/template/BroadcastMessageTemplate";
import EditBroadcastMessageTemplate from "./pages/broadcastMessage/template/EditBroadcastMessageTemplate";
import AddBroadcastMessageTemplate from "./pages/broadcastMessage/template/AddBroadcastMessageTemplate";
import DetailBroadcastMessageTemplate from "./pages/broadcastMessage/template/DetailBroadcastMessageTemplate";
//broadcast message
import ScheduledBroadcastMessageList from "./pages/broadcastMessage/ScheduledBroadcastMessageList";
import SentBroadcastMessageList from "./pages/broadcastMessage/SentBroadcastMessageList";
import AddBroadcast from "./pages/broadcastMessage/AddBroadcast";
import EditBroadcast from "./pages/broadcastMessage/EditBroadcast";
import DetailBroadcast from "./pages/broadcastMessage/DetailBroadcast";
import RejectedBroadcastMessageList from "./pages/broadcastMessage/RejectedBroadcastMessageList";
import WaitingApprovalBroadcastList from "./pages/broadcastMessage/WaitingApprovalBroadcastList";
import AllBroadcastList from "./pages/broadcastMessage/AllBroadcastList";

//instagram post
import InstagramPostList from "./pages/instagramPost/InstagramPostList";
import AddEditInstagramPost from "./pages/instagramPost/AddEditInstagramPost";
import DetailInstagramPost from "./pages/instagramPost/DetailInstagramPost";
import InstagramAccountList from "./pages/instagramPost/InstagramAccountList";

//customer service
import ChatRequestList from "./pages/chatbot/chat-request/ChatRequestList";
import LiveConversation from "./pages/chatbot/chat-request/LiveConversation";
import HistoryChat from "./pages/chatbot/HistoryChat";

//marketing
//marketing project
import CampaignList from "./pages/marketing/campaign/CampaignList";
import AddEditCampaign from "./pages/marketing/campaign/AddEditCampaign";
import DetailCampaign from "./pages/marketing/campaign/DetailCampaign";
//marketing staff
import MarketingStaff from "./pages/marketing/marketingStaff/MarketingStaffList";
//marketing task
import MarketingTaskList from "./pages/marketing/marketingTask/MarketingTaskList";
import AddEditMarketingTask from "./pages/marketing/marketingTask/AddEditMarketingTask";
//my marketing task
import MyMarketingTask from "./pages/marketing/myTask/MyMarketingTask";

//FunctionBlocking
//Meeting Room
import MeetingRoomList from "./pages/functionBlocking/meetingRoom/MeetingRoomList";
import AddEditMeetingRoom from "./pages/functionBlocking/meetingRoom/AddEditMeetingRoom";
import DetailMeetingRoom from "./pages/functionBlocking/meetingRoom/DetailMeetingRoom";
//Booking Room
import BookingRoomList from "./pages/functionBlocking/bookingRoom/BookingRoomList";
import AddEditBookingRoom from "./pages/functionBlocking/bookingRoom/AddEditMeetingRoom";
import DetailBookingRoom from "./pages/functionBlocking/bookingRoom/DetailMeetingRoom";
//Product Knowledge
import ProductKnowledgeList from "./pages/productKnowledge/ProductKnowledgeList";
import AddProductKnowledge from "./pages/productKnowledge/AddProductKnowledge";
import DetailProductKnowledge from "./pages/productKnowledge/DetailProductKnowledge";

//RoomCharting
import RoomChartingList from "./pages/roomCharting/roomChartingList";

//sales forecast
//sales hotel target
import SalesHotelTargetList from "./pages/salesForecast/salesHotelTarget/SalesHotelTargetList";
import AddEditSalesHotelTarget from "./pages/salesForecast/salesHotelTarget/AddEditSalesHotelTarget";
//sales representative staff
import SalesRepStaff from "./pages/salesForecast/salesRepStaff/SalesRepStaff";
import DetailSalesRep from "./pages/salesForecast/salesRepStaff/DetailSalesRep";
//sales representative target
import SalesRepTargetList from "./pages/salesForecast/salesRepTarget/SalesRepTargetList";
import AddEditSalesRepTarget from "./pages/salesForecast/salesRepTarget/AddEditSalesRepTarget";
//sales representative activity
import SalesRepActivityList from "./pages/salesForecast/salesRepActivity/SalesRepActivityList";
import DetailSalesRepActivity from "./pages/salesForecast/salesRepActivity/DetailSalesRepActivity";
//sales task
import SalesTaskList from "./pages/salesForecast/salesTask/SalesTaskList";
import AddEditSalesTask from "./pages/salesForecast/salesTask/AddEditSalesTask";
import EditProgress from "./pages/salesForecast/salesTask/EditProgress";
//sales deal won
import SalesDealWonList from "./pages/salesForecast/salesDealWon/SalesDealWonList";
//my sales task
import MySalesTask from "./pages/salesForecast/myTask/MySalesTask";
import ReportDeals from "./pages/salesForecast/myTask/reportDeals/ReportDeals";
import ReportActivity from "./pages/salesForecast/myTask/reportActivity/ReportActivity";
import AddEditReportActivity from "./pages/salesForecast/myTask/reportActivity/AddEditReportActivity";
import DetailMySalesTask from "./pages/salesForecast/myTask/DetailMySalesTask";

//backup and restore
//manual backup
import BackupList from "./pages/backupDatabase/BackupList";
//restore
import RestoreBackup from "./pages/backupDatabase/RestoreBackup";

//conversation time setup
import ConversationTimeSetup from "./pages/chatbot/chat-request/ConversationTimeSetup";

import io from "socket.io-client";
import axios from "axios";
import DetailSalesTask from "./pages/salesForecast/salesTask/DetailSalesTask";
// import { createContext } from "react";

function App() {
  return (
    <>
      <Content />
    </>
  );
}

export default App;

// export const MessageContext = createContext(null);

const Content = () => {
  const [userData, setUserData] = useState([]);
  const [getToken, setToken] = useState();

  const [time, setTime] = useState("fetching");
  const [newChatRequest, setNewChatRequest] = useState([]);
  const [newReviews, setNewReviews] = useState([]);
  const [newMessage, setNewMessage] = useState([]);
  const [hasFeatureChatRequest, setHasFeatureChatRequest] = useState(false);
  const [socketChatRequest, setSocketChatRequest] = useState(false);
  const [socketEmit, setSocketEmit] = useState(false);
  const [feature, setFeature] = useState([]);
  const [newWhatsAppMessage, setNewWhatsAppMessage] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const [uuidRole, setUuidRole] = useState();

  const [role, setRole] = useState("");
  let roleName;

  const socket = io(process.env.REACT_APP_WEBSOCKET_URL);

  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");

  const endpointGetUser = `${process.env.REACT_APP_BASE_URL}/users/`;
  const endpointGetChatRequestList = `${process.env.REACT_APP_BASE_URL}/chat-requests`;
  const endpointGetFeature = `${process.env.REACT_APP_BASE_URL}/roles/`;

  const getUserData = async () => {
    const user = localStorage.getItem("user");
    var stringifyUserData = JSON.parse(user);
    const uuid = stringifyUserData["uuid"];
    setUuidRole(stringifyUserData["uuid_role"]);

    try {
      await axios
        .get(endpointGetUser + uuid, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setRole(response.data.content.role.name);
          roleName = response.data.content.role.name;
        });
    } catch (error) {
      console.log(error.data.response.message);
    }
  };

  const getChatRequest = async () => {
    try {
      await axios
        .get(endpointGetChatRequestList, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          const data = response.data.content;
          const sortedData = data.sort((a, b) =>
            a.request_at < b.request_at ? 1 : -1
          );
          sortedData
            .filter((data1) => data1.status === "Onprogress")
            .map((data) =>
              socket.emit("joinChatRoom", {
                uuid_user: data.handle_by,
                uuid_chat_request: data.uuid,
              })
            );
        });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getUserDetail = async () => {
    if (token !== null && user !== null) {
      var stringifyUserData = JSON.parse(user);
      const uuid_role = stringifyUserData["uuid_role"];
      try {
        await axios
          .get(endpointGetFeature + uuid_role, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            setFeature(response.data.content.features);
          });
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (isLogout) {
      setFeature([]);
      setIsLogout(false);
      setIsLogin(false);
      setHasFeatureChatRequest(false);
      setSocketEmit(false);
    }
  }, [isLogout]);

  useEffect(() => {
    if (token) {
      getUserData();
      getUserDetail();
    }
  }, [token, isLogin]);

  useEffect(() => {
    if (role) {
      socket.on("connect", () => {
        setTime("Connected");
      });
      socket.on("connect_error", () => {
        setTimeout(() => socket.connect(), 5000);
        setTime("Reconnecting...");
      });
      if (!socketEmit) {
        socket.emit("joinCSNotificationRoom", uuidRole);
        socket.emit("joinReviewNotificationRoom");
        setSocketEmit(true);
      }
      socket.on("newChatRequestNotification", (notif) => {
        setNewChatRequest((prevNotif) => [...prevNotif, notif]);
      });
      socket.on("newReviewNotification", (notif) => {
        setNewReviews((prevNotif) => [...prevNotif, notif]);
      });
      socket.on("newMessageNotification", (notif) => {
        setNewMessage((prevNotif) => [...prevNotif, notif]);
      });
      socket.on("newWhatsappMessage", (notif) => {
        setNewWhatsAppMessage((prevNotif) => [...prevNotif, notif]);
      });

      socket.on("disconnect", () =>
        console.log("Disconnected from the server")
      );

      console.log("Socket.io: ", time);
    }
  }, [socket]);

  useEffect(() => {
    if (hasFeatureChatRequest === true) {
      getChatRequest();
    }
  }, [hasFeatureChatRequest]);

  return (
    <Routes>
      <Route
        index
        element={
          getToken === null ? (
            <Login setIsLogin={setIsLogin} />
          ) : (
            <Navigate to="/dashboard" replace />
          )
        }
      />
      <Route
        element={
          <Layout
            setIsLogout={setIsLogout}
            feature={feature}
            userData={userData}
            setUserData={setUserData}
            getToken={getToken}
            setToken={setToken}
            newChatRequest={newChatRequest}
            newReviews={newReviews}
            newMessage={newMessage}
            setHasFeatureChatRequest={setHasFeatureChatRequest}
          />
        }
      >
        <Route path="dashboard" element={<Dashboard feature={feature} />} />

        <Route path="profile" element={<Profile />} />

        {/* employee management */}
        <Route path="employee" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view employee" feature={feature}>
                <Employee feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create employee" feature={feature}>
                <AddEmployee feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view employee" feature={feature}>
                <EditEmployee feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* access management */}
        <Route path="user" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view user" feature={feature}>
                <Users feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute name="create user" feature={feature}>
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view user" feature={feature}>
                <EditUser feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="reset-password/:id"
            element={
              <ProtectedRoute name="reset password user" feature={feature}>
                <ResetPassword />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="role"
          element={
            <ProtectedRoute name="view role" feature={feature}>
              <RoleUser feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="feature-management"
          element={
            <ProtectedRoute name="update role" feature={feature}>
              <ManagementFeature />
            </ProtectedRoute>
          }
        />

        {/* customer management */}
        <Route path="customer" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view customer" feature={feature}>
                <CustomerList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create customer" feature={feature}>
                <AddCustomer feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view customer" feature={feature}>
                <EditCustomer feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* customer review */}
        <Route path="customer-review" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view review" feature={feature}>
                <CustomerReviewList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view review" feature={feature}>
                <AddEditReviewReply />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="department" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view department" feature={feature}>
                <Department feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="hotel" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view hotel" feature={feature}>
                <Hotel feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create hotel" feature={feature}>
                <AddEditHotel feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view hotel" feature={feature}>
                <AddEditHotel feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="product" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view product" feature={feature}>
                <ProductList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create product" feature={feature}>
                <AddEditProduct feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view product" feature={feature}>
                <AddEditProduct feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="partnership" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="View Partnership" feature={feature}>
                <PartnershipList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="Create Partnership" feature={feature}>
                <AddEditPartnership feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="View Partnership" feature={feature}>
                <AddEditPartnership feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="pic" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="View Person In Charge" feature={feature}>
                <PersonInChargeList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="Create Person In Charge" feature={feature}>
                <AddEditPic feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route path=":id" element={<AddEditPic feature={feature} />} />
        </Route>

        <Route path="platform" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view platform" feature={feature}>
                <Platform feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create platform" feature={feature}>
                <AddEditPlatform feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view platform" feature={feature}>
                <AddEditPlatform feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="hotel-account" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view hotel account" feature={feature}>
                <HotelAccount feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create hotel account" feature={feature}>
                <AddEditHotelAccount feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view hotel account" feature={feature}>
                <AddEditHotelAccount feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* tidak ada feature */}
        <Route
          path="instagram-account"
          element={
            <ProtectedRoute name="View Instagram Account" feature={feature}>
              <InstagramAccountList feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="product-type"
          element={
            <ProtectedRoute name="view product type" feature={feature}>
              <ProductType feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="customer-segmentation"
          element={
            <ProtectedRoute name="view customer segmentation" feature={feature}>
              <CustomerSegmentation feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="response-message"
          element={
            <ProtectedRoute name="View Response Message" feature={feature}>
              <ResponseMessageList feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="faq"
          element={
            <ProtectedRoute name="View FAQ" feature={feature}>
              <FaqList feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="backup-time-setup"
          element={
            <ProtectedRoute name="Configure Database Backup" feature={feature}>
              <BackupTimeSetup />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-time-setup"
          element={
            <ProtectedRoute
              name="Configure Converstation Hour Limit"
              feature={feature}
            >
              <ConversationTimeSetup />
            </ProtectedRoute>
          }
        />

        <Route path="whatsapp-broadcast-template" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view message template" feature={feature}>
                <BroadcastMessageTemplate feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute name="create message template" feature={feature}>
                <AddBroadcastMessageTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute name="update message template" feature={feature}>
                <EditBroadcastMessageTemplate />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view message template" feature={feature}>
                <DetailBroadcastMessageTemplate />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="whatsapp-broadcast/all"
          element={
            <ProtectedRoute name="view broadcast" feature={feature}>
              <AllBroadcastList feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/create"
          element={
            <ProtectedRoute name="create broadcast" feature={feature}>
              <AddBroadcast />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/:id"
          element={
            <ProtectedRoute name="view broadcast" feature={feature}>
              <DetailBroadcast />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/edit/:id"
          element={
            <ProtectedRoute name="update broadcast" feature={feature}>
              <EditBroadcast />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/scheduled"
          element={
            <ProtectedRoute name="View Scheduled Broadcast" feature={feature}>
              <ScheduledBroadcastMessageList />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/waiting-approval"
          element={
            <ProtectedRoute
              name="View Waiting Approval Broadcast"
              feature={feature}
            >
              <WaitingApprovalBroadcastList feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/sent"
          element={
            <ProtectedRoute name="View Sent Broadcast" feature={feature}>
              <SentBroadcastMessageList />
            </ProtectedRoute>
          }
        />

        <Route
          path="whatsapp-broadcast/rejected"
          element={
            <ProtectedRoute name="View Rejected Broadcast" feature={feature}>
              <RejectedBroadcastMessageList feature={feature} />
            </ProtectedRoute>
          }
        />

        {/* <Route path="instagram-post" element={<Outlet />}>
          <Route index element={<InstagramPostList />} />
          <Route path="add" element={<AddEditInstagramPost />} />
          <Route path="edit/:id" element={<AddEditInstagramPost />} />
          <Route path=":id" element={<DetailInstagramPost />} />
          <Route path="account-list" element={<InstagramAccountList />} />
          <Route path="*" element={<NotFound />} />
        </Route> */}

        <Route
          path="whatsapp-chat-request"
          element={
            <ProtectedRoute name="View Chat Request" feature={feature}>
              <ChatRequestList
                feature={feature}
                setSocketChatRequest={setSocketChatRequest}
                newChatRequest={newChatRequest}
              />
            </ProtectedRoute>
          }
        />

        <Route path="marketing-project" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view marketing project" feature={feature}>
                <CampaignList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute name="create marketing project" feature={feature}>
                <AddEditCampaign feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view marketing project" feature={feature}>
                <DetailCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute name="update marketing project" feature={feature}>
                <AddEditCampaign feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="marketing-task" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view marketing task" feature={feature}>
                <MarketingTaskList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="create marketing task" feature={feature}>
                <AddEditMarketingTask feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view marketing task" feature={feature}>
                <AddEditMarketingTask feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route
          path="marketing-staff"
          element={
            <ProtectedRoute name="View Marketing Staff" feature={feature}>
              <MarketingStaff />
            </ProtectedRoute>
          }
        />

        {/* Room Charting */}
        <Route path="room-charting" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view room charting" feature={feature}>
                <RoomChartingList feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* View Booking Room */}
        <Route path="function-block/booking-room" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view booking room" feature={feature}>
                <BookingRoomList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view booking room" feature={feature}>
                <DetailBookingRoom />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute name="create booking room" feature={feature}>
                <AddEditBookingRoom feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute name="update meeting room" feature={feature}>
                <AddEditBookingRoom feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* View Meeting Room */}
        <Route path="function-block/meeting-room" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view meeting room" feature={feature}>
                <MeetingRoomList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute name="create meeting room" feature={feature}>
                <AddEditMeetingRoom feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view meeting room" feature={feature}>
                <DetailMeetingRoom />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute name="update meeting room" feature={feature}>
                <AddEditMeetingRoom feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* View Product Knowledge */}
        <Route path="product-knowledge" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="view Product Knowledge" feature={feature}>
                <ProductKnowledgeList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute name="create Product Knowledge" feature={feature}>
                <AddProductKnowledge feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="view Product Knowledge" feature={feature}>
                <DetailProductKnowledge />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute name="update Product Knowledge" feature={feature}>
                <AddProductKnowledge feature={feature} />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="my-task/marketing-task"
          element={
            <ProtectedRoute name="View My Marketing Task" feature={feature}>
              <MyMarketingTask feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route path="sales-hotel-target" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="View Sales Target" feature={feature}>
                <SalesHotelTargetList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="Create Sales Target" feature={feature}>
                <AddEditSalesHotelTarget feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="View Sales Target" feature={feature}>
                <AddEditSalesHotelTarget feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="sales-task" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="View Sales Task" feature={feature}>
                <SalesTaskList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute name="Create Sales Task" feature={feature}>
                <AddEditSalesTask feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="View Sales Task" feature={feature}>
                <DetailSalesTask />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute name="Update Sales Task" feature={feature}>
                <AddEditSalesTask feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add-progress/:id"
            element={
              <ProtectedRoute name="Progress Sales Task" feature={feature}>
                <EditProgress />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="sales-representative-target" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute
                name="View Sales Representative Target"
                feature={feature}
              >
                <SalesRepTargetList feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute
                name="Create Sales Representative Target"
                feature={feature}
              >
                <AddEditSalesRepTarget feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute
                name="View Sales Representative Target"
                feature={feature}
              >
                <AddEditSalesRepTarget feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route
          path="sales-deal-won"
          element={
            <ProtectedRoute name="View Sales Deal Won" feature={feature}>
              <SalesDealWonList />
            </ProtectedRoute>
          }
        />

        <Route path="sales-representative-staff" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute
                name="View Sales Representative"
                feature={feature}
              >
                <SalesRepStaff />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute
                name="View Sales Representative"
                feature={feature}
              >
                <DetailSalesRep />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="sales-representative-activity" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute
                name="View Sales Representative Activity"
                feature={feature}
              >
                <SalesRepActivityList />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute
                name="View Sales Representative Activity"
                feature={feature}
              >
                <DetailSalesRepActivity />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="my-task/sales-task" element={<Outlet />}>
          <Route
            index
            element={
              <ProtectedRoute name="View My Sales Task" feature={feature}>
                <MySalesTask feature={feature} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id"
            element={
              <ProtectedRoute name="View My Sales Task" feature={feature}>
                <DetailMySalesTask />
              </ProtectedRoute>
            }
          />
          <Route
            path="report-deals/:id"
            element={
              <ProtectedRoute name="Create Sales Deal Won" feature={feature}>
                <ReportDeals />
              </ProtectedRoute>
            }
          />
          <Route
            path="report-activities/:id"
            element={
              <ProtectedRoute
                name="Create Sales Representative Activity"
                feature={feature}
              >
                <ReportActivity />
              </ProtectedRoute>
            }
          />
          <Route
            path="report-activities/:id/add"
            element={
              <ProtectedRoute
                name="Create Sales Representative Activity"
                feature={feature}
              >
                <AddEditReportActivity />
              </ProtectedRoute>
            }
          />
          <Route
            path="report-activities/:id/edit/:idAct"
            element={
              <ProtectedRoute
                name="Update Sales Representative Activity"
                feature={feature}
              >
                <AddEditReportActivity />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="backup-database"
          element={
            <ProtectedRoute name="View Backup" feature={feature}>
              <BackupList feature={feature} />
            </ProtectedRoute>
          }
        />

        <Route
          path="restore-database-via-file"
          element={
            <ProtectedRoute name="Restore Backup" feature={feature}>
              <RestoreBackup />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="whatsapp-live-conversation/:id"
        element={
          <ProtectedRoute name="Create Conversation" feature={feature}>
            {/* <MessageContext.Provider value={newWhatsAppMessage}> */}
            <LiveConversation
              userData={userData}
              newWhatsAppMessage={newWhatsAppMessage}
            />
            {/* </MessageContext.Provider> */}
          </ProtectedRoute>
        }
      />
      <Route
        path="history-chat/:id"
        element={
          <ProtectedRoute name="View Conversation" feature={feature}>
            <HistoryChat />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
